.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-x2, .container-x3 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.container-slider {
    padding: 0;
    position: relative;
}

.container-slider .slider-img {
    background: #000;
    line-height: 0;
}

.container-slider .slider-img img {
    opacity: .5;
    width: 100%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
    width: inherit;
}

.container-slider .slider-mask {
    position: absolute;
    bottom: -57px;
    left: 0;
    width: 100%;
    max-width: 100%;
}

.container-slider .slider-content {
    position: absolute;
    z-index: auto;
    height: auto;
    width: auto;
    left: 0;
    right: 0;
    bottom: 50%;
    transform: translateY(calc(50% + 50px));
    margin-left: 50px;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.slider-content .row .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.container-slider .slider-content .box-slider {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.container-slider .slider-content .section-title {
    font-size: 4.8rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    margin: 0;
    line-height: 1;
    text-align: initial;
}

.new-class-btn {
    text-transform: uppercase;
    background: #00b251;
    background: -webkit-linear-gradient(#00b251 0%, #007152 100%);
    background: -o-linear-gradient(#00b251 0%, #007152 100%);
    background: linear-gradient(#00b251 0%, #007152 100%);
    border: 0;
    border-radius: 6px 0 6px 0;
    padding: 1.25rem 2.5rem;
    font-weight: 700;
    transition: all .2s linear;
    color: #fff;
    margin-top: 15px;
    display: inline-block;
    line-height: 1.65;
}

.new-class-btn:hover {
    color: #00b250;
}

.gray-background {
    background: #EBEBEB;
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.gray-background .environmental-services {
    display: flex;
    justify-content: center;
}

.gray-background .service {
    display: flex;
    flex-direction: column;
}

.gray-background .service .new-row {
    margin: 1rem auto !important;
}

.gray-background .environmental-services .row-offer {
    margin: 1rem 0;
    justify-content: space-evenly;
    max-width: 1000px;
}

.gray-background .environmental-services .section-title {
    font-size: 4.8rem;
    color: #404040;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    margin: 0;
    line-height: 1;
    text-align: initial;
}

.line {
    flex: 0 0 70%;
    border-top: 2px dashed;
    margin-left: 2rem;
    max-width: 70%;
}

.about-us {
    background: linear-gradient(#00b251 0%, #007152 100%);
}

.about-us .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
}

.about-us .col-12 a {
    position: absolute;
    right: 50px;
    bottom: 2rem;
    background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: all .2s linear;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    color: #00b250;
}

.about-us img {
    width: 90px;
    height: 90px;
    object-fit: scale-down;
}

.about-us .img {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-us .icon-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 50px;
}

.about-us .icon-wrapper .icon-item {
    flex: 0 0 25%;
    margin-right: 4rem;
    max-width: calc(25% - 4rem);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    text-align: initial;
}

.about-us .icon-wrapper .icon-item  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    line-height: 30px;
}
.about-us .icon-wrapper .icon-item  p {
    line-height: 1.3;
    font-weight: 300;
    text-align: initial;
    margin: 0;
}

.gray-background .about-ecofox {
    display: flex;
    padding: 2rem 0;
}

.gray-background .about-ecofox .col-left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-right: 4rem;
    font-size: 1.125rem;
    line-height: 30px;
}

.gray-background .about-ecofox .col-left .section-title {
    font-size: 3rem;
    font-weight: bold;
    color: #404040;
    margin: 1.5rem 0;
    line-height: initial;
}

.gray-background .about-ecofox .col-left p {
    color: #404040;
    text-align: initial;
}

.gray-background .about-ecofox .col-left .section-title font {
    color: #00b250;
}

.gray-background .about-ecofox .col-right {
    max-width: 50%;
    flex: 0 0 100%;
}

@media only screen and (max-width: 1199.98px) {
    .container-slider .slider-content {
        margin-top: 8rem;
    }
}

@media only screen and (max-width: 1439.98px) {
    .container-slider .slider-content {
        margin-top: 10rem;
    }
    .gray-background .environmental-services .section-title,
    .container-slider .slider-content .section-title,
    .gray-background .about-ecofox .col-left .section-title,
    .green-section .row .section-title {
        font-size: 2.2rem !important;
    }
}

@media only screen and (max-width: 1719.98px) {
    .container-slider .slider-content {
        margin-top: 12rem;
    }
    .container-slider .slider-content .section-title {
        font-size: 3rem;
    }
    .gray-background .environmental-services .section-title {
        font-size: 3rem;
    }
}

@media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 768px) {
    .container, .container-sm, .container-md {
        max-width: 720px;
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
}
