.breadcrumb li {
    color: #404040 !important;
    align-items: center;
    display: flex;
    font-size: .875rem;
}

.breadcrumb {
    padding: 10px 50px;
    display: flex;
    align-items: center;
}
