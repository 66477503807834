.technical-supervision-container .gray-background .environmental-services h3 {
    background: #EBEBEB;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 1.125rem !important;
    font-weight: bold;
    line-height: 30px;
    color: #404040;
    text-align: initial;
}

.technical-supervision-container .gray-background .article {
    max-width: 100%;
    text-align: justify;
    padding: 0 15px;
}
