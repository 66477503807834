.header {
  color: #fff;
  height: 60px;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.header.sps--blw {
  position: fixed;
  background: #00b251;
  background: -webkit-linear-gradient(#00b251 0%, #007152 100%);
  background: -o-linear-gradient(#00b251 0%, #007152 100%);
  background: linear-gradient(#00b251 0%, #007152 100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 2;
  animation: zoomIn;
  animation-delay: 1s;
  transition: all .2s linear;
  height: 60px;
}

.header .contact-header {
  min-width: fit-content;
  padding-left: 10px;
  font-size: 1.125rem;
}

.header .contact-header a {
  color: white;
  padding-left: 5px;
}

.header .contact-header:hover, .header .contact-header:hover a {
  color: #00b250;
}

.content {
  text-align: center;
  min-height: 200px;
  line-height: 120px;
  color: #fff;
  background-color: #fff;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.footer {
  color: #404040;
  padding: 0;
}

.logo {
  height: 50px;
}
.logo-footer {
  height: 200px;
}

.footer .container-footer {
  background: #fff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.container-footer .item .list-unstyled {
  list-style: none;
  padding: 0;
}

.container-footer .item .list-unstyled li {
  margin-top: 0.2rem;
}

.container-footer .item .list-unstyled li a {
  color: #404040;
}

.container-footer .item .list-unstyled .list-contact a {
  padding-left: 5px;
}

.container-footer .item .list-unstyled li a:hover {
  color: #00b250;
}
.container-footer .item .list-unstyled .list-contact:hover {
  color: #00b250;
}

.container-footer .item .list-unstyled .list-contact span {
  color: #00b250;
}

.footer .wrapper {
  justify-content: space-around;
  width: inherit;
  margin: 0;
}

.footer .wrapper .item {
  padding: 0 15px;
  max-width: 25%;
}

.footer .wrapper .item h4 {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  .container-slider .slider-mask {
    bottom: -60px;
  }
  .container-slider .slider-content {
    display: flex;
    align-items: center;
    margin: 12rem auto 0;
  }
  .container-slider .slider-content .section-title {
    text-align: center;
    line-height: 1.1;
  }
  .gray-background .about-ecofox {
    display: flex;
    flex-direction: column;
  }
  .gray-background .about-ecofox .col-left {
    padding: 0;
  }
  .gray-background .about-ecofox .col-left .section-title {
    margin-bottom: 0;
  }
  .gray-background .about-ecofox .col-left p {
    text-align: justify;
  }
  .gray-background .about-ecofox .col-right {
    max-width: 100%;
  }
  .about-us .icon-wrapper .icon-item {
    margin-right: 0;
    flex: 50% 1;
    max-width: 50%;
    align-items: center;
  }
  .about-us .icon-wrapper .icon-item p {
    text-align: center;
  }
  .line {
    display: none;
  }
  .about-us .col-12 a {
    right: 25px;
    bottom: 25px;
  }
  :where(.css-dev-only-do-not-override-dfjnss).ant-menu-submenu-popup.ant-menu-submenu {
    right: 25px !important;
  }
  .logo-footer {
    height: 85px;
  }
  .footer .wrapper .item {
    padding: 0;
  }
  .footer .wrapper .item h4 {
    font-size: 13px;
  }
  .gray-background .article {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 0 10px;
  }
  .header .contact-header {
    display: none;
  }
  .header .dropdown {
    position: absolute;
    right: 60px;
  }
  :where(.css-dev-only-do-not-override-dfjnss).ant-menu-submenu-popup.ant-menu-submenu {
    right: 0 !important;
  }
  .container-slider .slider-content {
    bottom: 68%;
  }
  .container-slider .slider-content .new-class-btn {
    padding: 10px;
    margin-top: 10px;
    /*display: none;*/
  }
  .container-slider .slider-mask {
    bottom: -60px;
  }
  .gray-background .environmental-services .section-title, .container-slider .slider-content .section-title, .gray-background .about-ecofox .col-left .section-title, .green-section .row .section-title {
    font-size: 1rem !important;
  }
  .container-slider .slider-content .section-title {
    text-align: center;
  }
  .gray-background {
   padding: 10px;
  }
  .gray-background .environmental-services .row-offer {
    margin: 0;
  }
  .ant-card {
    margin-left: 0;
    margin-top: 10px;
  }
  .gray-background .about-ecofox {
    flex-direction: column;
    padding: 0;
  }
  .gray-background .about-ecofox .col-left {
    padding-right: 0;
  }
  .gray-background .about-ecofox .col-left .section-title {
    margin-bottom: 0;
  }
  .gray-background .about-ecofox .col-left p {
    font-size: 1rem;
    margin-top: 0;
    text-align: justify;
  }
  .gray-background .about-ecofox .col-right {
    max-width: 100%;
  }
  .ant-carousel, .slick-slider, .slick-list {
    height: 250px;
  }

  .about-us .icon-wrapper {
    padding: 50px 10px 0 10px;
  }
  .about-us .col-12 a {
   display: none;
  }
  .about-us .icon-wrapper .icon-item {
    margin-right: 0;
    flex: 50%;
    max-width: 50%;
    align-items: center;
  }
  .line {
    display: none;
  }
  .about-us .icon-wrapper .icon-item p {
    text-align: center;
  }
  .footer .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .footer .wrapper .item {
    max-width: 60%;
  }
  .footer .container-footer {
    padding: 10px;
  }
  .logo-footer {
    display: none;
  }
  .footer .wrapper .service {
    display: none;
  }

  .breadcrumb {
    padding: 10px;
  }

  .green-section {
    height: 200px;
  }
  .gray-background .article {
    padding: 0;
    max-width: 100%;
  }
  .green-section .row p {
    display: none;
  }
  :where(.css-dfjnss).ant-menu-submenu-popup.ant-menu-submenu {
    right: 0 !important;
    top: 50px !important;
    left: auto !important;
    position: fixed;
  }
  :where(.css-dfjnss).ant-menu .ant-menu-submenu-title .anticon {
    font-size: 20px;
    color: #fff;
  }
}
