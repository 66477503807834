.header ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: none;
    color: #fff;
    overflow: hidden;
}

.header ul li {
    font-size: 1.125rem;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected {
    color: #fff !important;
}

.header ul li a:hover {
    color: #00b250 !important;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    color: #00b250;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-submenu-popup.ant-menu-submenu {
    right: 50px !important;
    top: 50px !important;
    left: auto !important;
    position: fixed;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-dfjnss).ant-menu-light>.ant-menu .ant-menu-item-selected {
    color: #404040;
}

:where(.css-dev-only-do-not-override-dfjnss).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-dfjnss).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    color: #00b250;
    transition: color 0ms;
}

:where(.css-dfjnss).ant-menu-light .ant-menu-item-selected a {
    color: #fff;
}
