.green-section {
    height: 450px;
    position: relative;
}

.green-section .slider-img {
    height: 100%;
    overflow: hidden;
    border-bottom-right-radius: 200px;
    background: #000;
}

.green-section .slider-img img {
    height: 100%;
    object-fit: cover;
    opacity: .5;
    width: 100%;
    max-width: 100%;
}

.green-section .row {
    position: absolute;
    z-index: auto;
    height: auto;
    width: auto;
    bottom: 60%;
    transform: translateY(calc(50% + 50px));
    margin: 0;
    padding-left: 50px;
    max-width: 66.66667%;
}

.green-section .row .section-title {
    font-size: 4.8rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 25px;
    text-align: initial;
}

.green-section .row p {
    color: #fff;
    margin: 0;
    line-height: 1.4;
    text-align: initial;
}
