.about-us-container .about-us-text {
    background: #EBEBEB;
    padding: 50px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 1.125rem;
    line-height: 30px;
}

.about-us-container .about-us-text p {
    color: #404040;
    text-align: justify;
}

.about-us-container .about-us-text ul {
    color: #404040;
    text-align: justify;
}
