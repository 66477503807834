.ant-carousel, .slick-slider, .slick-list {
    height: 100%;
}

.carousel {
    height: 100%;
}

.carousel img {
    width: 100%;
    height: 400px;
}

