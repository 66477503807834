.ant-card {
    width: 300px;
    max-height: 130px;
    cursor: pointer;
    margin-top: 25px;
    margin-left: 25px;
    box-sizing: border-box;
}

.ant-card .ant-card-body {
    display: flex;
    max-height: 130px;
    padding: 0;
    border-radius: 5px;
    width: 100%;
}

.ant-card .ant-card-body a {
    display: flex;
    width: 100%;
}

.ant-card .ant-card-body a span {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    width: 35px;
    height: 35px;
    background: #a6a6a6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-top-left-radius: 6px;
    transition: all .2s linear;
}

.ant-card .ant-card-body a span svg {
    height: 25px;
    width: 25px;
}

.ant-card-body .icon {
    flex: 0 0 30%;
    background: #f9f9f9;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    transition: all .2s linear;
}

.ant-card-body .icon img {
    width: 70px;
    height: 70px;
}

.ant-card .ant-card-body h4 {
    color: #000;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-card .ant-card-body:hover .icon, .ant-card .ant-card-body:hover span {
    background: #00b250;
}
.ant-card .ant-card-body:hover h4 {
    color: #00b250;
}

