.gray-background .article {
    max-width: 60%;
    padding: 3rem;
    color: #404040;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.65;
    text-align: initial;
}

.gray-background .article p {
    text-align: justify;
}

.gray-background .article ul li::marker {
    content: "› ";
    color: #00b250;
}
